@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/ag-grid-community/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/styles/ag-theme-alpine.css";
@import '../node_modules/@angular/cdk/overlay-prebuilt.css';

html {min-height: 100%;  position: relative; }
body { padding-bottom:120px;  
    min-width:700px; }
a { color: #0090e3; }

.nav-tabs { background-color: #F9F9F9; padding-top: 8px; margin-bottom: 20px; }
.nav-tabs>li>a { border-radius:0px; }

.form-control { display: inline; width: auto; }
.form-control-feedback { display: inline; position: relative; pointer-events: auto; }

.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 4px 5px;
}
.field-indented { margin-left: 40px; }

label { font-weight: normal; }
input[type="checkbox"], input[type="radio"] { margin-top: 0; }
input[type="number"] { box-sizing: content-box; }
input + label { padding-left: 4px; }

.btn, button {
  background-image: none;
  text-shadow: none;
  box-shadow: none;
  transition: all 0.3s;
  color: #333333;
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border-bottom-color: #b3b3b3;
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  background-color: #e6e6e6;
}
.btn-primary, btn-primary.active, .btn-primary.disabled, .btn-primary:active, .btn-primary:focus, .btn-primary:hover, .btn-primary[disabled] {
  color: #ffffff;
  background-color: #0090e3;
  border: 0px solid transparent !important;
}
.btn-primary:hover {background-color:#034A72;}
.btn-toolbar {
  margin-top: 10px;
  margin-bottom: 10px;
}
.btn-link {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  color: #0088cc;
}
.badge-info {
  background-color: #3a87ad;
}

input, textarea, select, .input { max-width:100%; background-image: none; border: 1px solid #ccc; background-color:white;border-radius: 4px; box-shadow: 0 0 0 rgba(0, 0, 0, 0.07) inset; color: #555; font-size: 14px; line-height: 1.42857;  padding: 6px; transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s; vertical-align: middle;}
input:disabled, textarea:disabled, select:disabled, label.disabled, span.disabled { color: lightslategray; }
input.ng-dirty:invalid { border: 2px solid red; }


input:focus, textarea:focus, select:focus {  border-color: #66afe9;  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);  outline: 0 none; }
input[type="time"]::-webkit-clear-button { display: none; }
input[type="time"]::-ms-clear { display: none; width: 0; height: 0; }

.container { width: 95%; margin: auto; padding: 0px; }
#content h1, .modal-header h1 { margin-top: 0; color:#034A72; font-weight:normal; }

.bar-row { overflow: auto; }

ul.list { list-style: none; margin-left: 0px; }

/* Accordion */
.accordion { background-color: #034a72; color: white; cursor: pointer;  padding: 10px; width: 100%; text-align: left; border: none; outline: none; transition: 0.4s; }
.accordion:after { content: '\02795'; /* Unicode character for "plus" sign (+) */ font-size: 13px; color: white; float: right; margin-left: 5px; }
.acc_active:after { content: "\2796"; /* Unicode character for "minus" sign (-) */ }
.acc_active, .accordion:hover {  background-color: #0090e3; }
.acc_panel { padding: 0 18px; background-color: white; overflow: hidden; transition: max-height 0.2s ease-out; }
.acc_panel_inactive { display: none; max-height: 0; }
.acc_panel_active { display: block; }

/* Blank icon (14 x 14) px */
.glyphicon-none:before { content: "\e009"; color: transparent !important; }

/* Common */

h1 { font-size: 24px; }
h2 { font-size: 20px; font-weight: bold; }
h3 { font-size: 16px; font-weight: bold; }
h4 { margin-top: 0; color:#034A72; font-weight:normal; margin: 15px 0px 0px 0px;}
ul.list { list-style: none; margin-left: 0px; padding-left: 0px; }

.filterbar { margin-bottom: 10px; }

table.table-click > tbody > tr > td { cursor: pointer; }
table.table-click > tbody > tr:hover > td { background-color: #CCCCCC; }

table.table-enabled-click > tbody > tr > td { color: #999999; } /* .muted */
table.table-enabled-click > tbody > tr.enabled > td { cursor: pointer; color: #333333; } /* body color */
table.table-enabled-click > tbody > tr.enabled:hover > td { background-color: #CCCCCC; }

table.table-click > tbody > tr.selected > td,
table.table-enabled-click > tbody > tr.selected > td
    { background: #0088CC; color: #FFFFFF; }

table.table-click-header > tbody > tr:hover > th { cursor: pointer; }

form.form div.field,
form.form div.field_group > div.field { margin-bottom: 10px; }
form.form div.field > label:first-child,
form.form div.field_group > div.field > label:first-child { display: inline-block; width: 120px; }
.buttons { margin-top: 20px; }

.ellipsis { overflow: hidden; text-overflow: ellipsis; }

ul.tree { list-style: none; margin-left: 0px; }
ul.tree > li > i.tree-node-toggle { cursor: pointer; }
ul.tree > li > span { cursor: pointer; }
ul.tree > li span.selected { background: #0088CC; color: #FFFFFF; }
ul.tree-subtree { padding-left: 15px; }

.modal, .modal.wide {
  position: absolute;
  display: inline;
  left: 50%;
  width: 600px;
  bottom: auto;
  margin-left: -300px;
  margin-top: -60px;
  overflow: visible;
  border: 0px solid transparent;
  background-color: #ffffff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.modal-header h1 { margin: 0; line-height: 30px; }

.modal.wide { width: 800px; margin-left: -400px; }
.modal-backdrop {    background-color: #000; opacity:0.5;}

.modal-subtitle { padding:10px 0px; }
.modal-subtitle div { padding-top:4px; }
.subtitle_label {font-weight:bold;}

.clickable:hover { cursor: pointer;}

/* edit-select */

.combobox { display: inline-block; position: relative; }
.combobox span {
    cursor: pointer;
    width: 20px; height: 25px;
    position: absolute; top: 4px; right: 0;
}
.combobox i { margin-top: 3px; }
.combobox .list {
    margin-top: -1px;
    cursor: pointer;
    border: 1px solid #CCCCCC;
    background-color: #FFFFFF;
    position: absolute;
    min-width: 99%;
    z-index: 10;
}
.combobox li {
    padding: 2px 5px;
}
.combobox li:hover {
    background: #0088CC; color: #FFFFFF;
}

/* Global AG-Grid tweaks */

div.ag-theme-alpine .ag-header-row {
    height: 35px;
}
div.ag-theme-alpine .ag-header-cell {
    padding-left: 7px;
    padding-right: 7px;
}
div.ag-theme-alpine .ag-row {
    height: 28px;
}
div.ag-theme-alpine .ag-cell {
    line-height: 25px;
    padding-left: 8px;
    padding-right: 8px;
}
/* Align AG-Grid underneath isolated pull-right elements */
.fen_ag_grid_align { clear: right; margin-bottom: 4px; }

/* Overlay backdrop styling */
.overlay-backdrop {
  background-color: rgba(0, 0, 0, 0.2);
}

/* Overlay panel styling */
.overlay-panel {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px;
}

/* Log and Auditlog child entries */
.log-child-entry {
    color: gray !important;
}

/* Jobs */
.job_marked_for_deletion { background: #EECC99; }

/* Monitoring footer */
.monitoring_footer {
  border-top: 1px solid #e5e5e5;
  padding: 15px;
  width: 100%;
  bottom: 0;
}
.monitoring_footer > div.buttons {
  text-align: right;
}
.monitoring_footer > div.buttons .btn + .btn {
  margin-left: 5px;
}
/* Monitoring description boxes */
#monitoring_description { box-sizing: content-box; height: 60px; overflow: auto }

/* Outbound Service Routing and Number Substitution */
#block { width: 200px; }
